import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { canUseDOM } from 'exenv';

const MapWrapper = ({ google }) => {
  if (!canUseDOM) { return null; }
  return (
    <Map
      google={google}
      zoom={14}
      style={{ height: '100%', width: '100%', position: 'relative' }}
      initialCenter={{
        lat: 50.6188378,
        lng: 26.2436703
      }}
    >
      <Marker
        title={'Наш магазин'}
        name={'HOROSHKA'}
        position={{
          lat: 50.6188378,
          lng: 26.2436703
        }}
      />
    </Map>
  );
};

MapWrapper.propTypes = {
  google: PropTypes.object.isRequired,
};

const key = 'AIzaSyBv03C_qKB_JdC0qvMSXQxRIYwXKfx0hfs';

export default GoogleApiWrapper({
  apiKey: key
})(MapWrapper);
